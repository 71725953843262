import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Image from "../components/image"

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`Montessori`, `montessori`, `Montessori Material`]}
      />

      <section className="mt-10 mb-10 mx-auto max-w-7xl sm:mt-12 sm:mb-12 md:mt-16 md:mb-16 lg:mt-20 lg:mb-20 xl:mt-28 xl:mb-28">
        <div className="sm:text-left lg:text-left">
          <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
            <span className="block xl:inline">Hello, I&apos;m Yachee 👋</span>
          </h1>
          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
            Welcome to Montessori Ingenious Creative Utilities (Montessori ICU). 
            This site was born to support you to create your own Montessori Materials for FREE. 
            Please don&apos;t hesitate to send me your feedback.
          </p>
        </div>

        <div className="flex flex-wrap -mx-3 lg:-mx-6">
          {[
              {
                route: `/cce`,
                title: `Classified Cards Editor`,
                image: "cce.png",
              },
            ].map((link) => (
              <div
                className="w-full sm:w-1/2 lg:w-1/3 p-3 md:p-6"
                key={link.title}
              >
                <div className="bg-white h-full shadow rounded-md overflow-hidden group">
                  <Link to={link.route}>
                    <div className="group-hover:opacity-75 transition duration-150 ease-in-out">
                    <Image 
                    className="object-fit rounded-t-md" 
                    assetUrl={link.image} 
                    alt={link.title} />
                    </div>
                    <div className="p-4 sm:p-5">
                      <h1 className="sm:text-lg text-gray-900 font-semibold">{link.title}</h1>
                    </div>
                  </Link>
                </div>
              </div>
            ))
          } 
        </div>
      </section>
    </Layout>
  );
}

export default IndexPage;
